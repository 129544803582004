.swiper {
  width: 100%;
  height: 100%;
}

  .mySwiper2 .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mySwiper2 .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .mySwiper2 .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mySwiper2 .swiper-slide,.mySwiper .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 600px;
    width: 100%;
  }
  
  .mySwiper {
    height: 10%;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
  }
  
  
  .mySwiper .swiper-slide {
    width: 10%;
    height: 100%;
    opacity: .4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .mySwiper2 img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: black;
  }
  @media screen and (max-width:700px) {
    .mySwiper2 {
        height: 350px;
      }
      .mySwiper {
        height: 4%;
      }
      .mySwiper2 .swiper-slide img {
        object-fit: cover;
      }
      .mySwiper2 img{
        object-fit: cover;
      }
  }
  